.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto; /* Enable scrolling */
  position: relative; /* Position relative for absolute positioning of close button */
}

.close-button {
  background: none;
  border: none;
  font-size: 34px;
  cursor: pointer;
  position: absolute; /* Position absolute to the modal content */
  top: 10px; /* Distance from the top */
  left: 95%; /* Distance from the right */
  color: #000; /* Color of the close button */
  margin: 0;
  position: sticky;
  z-index: 1000;
  width: fit-content;
}
@media (max-width: 768px) {
  .close-button {
    font-size: 24px;
  }
}
.modal-body {
  margin-top: 40px; /* Space for the close button */
}