.portfolio-multi {
  width: 100%;
  height: 500px;
  position: relative;
  
  max-width: 90%;
  margin: 0 auto;
}
.portfolio-multi .swiper {
  width: 100%;
  height: 100%;
}

.portfolio-multi .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border: 2px solid #663c2a;
  filter: grayscale(1);
}
.portfolio-multi .swiper-slide:hover {
  filter: grayscale(0);
}
@media (max-width: 768px) {
  .portfolio-multi .swiper-slide {
    filter: grayscale(0);
  }
}
.portfolio-multi div button {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: #00000078;
  width: 100%;
  color: white;
  height: 100px;
  display: flex;
  align-items: center;
  place-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s;
}
.portfolio-multi .swiper-slide:hover button {
  opacity: 1;
}
.portfolio-multi div button:hover {
  background: #00000078 !important;
  text-decoration: underline;
}
.rotate45{
  transform: rotate(-45deg);
}
.portfolio-multi .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.portfolio-multi .swiper {
  overflow: unset !important;
}
.portfolio-multi .swiper-pagination {
  bottom: -7%;
  z-index: 9;
}
.portfolio-multi .swiper-pagination-bullet {
  background: black;
  width: 15px;
  height: 15px;
}
.portfolio-multi .swiper-pagination-bullet-active {
  background: #663c2a;
  width: 15px;
  height: 15px;
}
