.footer {
    background-color: #C1B59F;
    color: #3f3f3e;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    margin-right: 20px;
  }
  
  .logo-section {
    flex: 2;
  }
  
  .footer-logo {
    width: 150px;
  }
  
  .social-icons {
    margin-top: 15px;
  }
  
  .social-icons a {
    color: #3f3f3e;
    font-size: 18px;
    margin-right: 10px;
    text-decoration: none;
  }
  
  .footer-section h3 {
    margin-bottom: 15px;
    color: #3f3f3e;

  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
    color: #3f3f3e;

  }
  
  .footer-section ul li a {
    text-decoration: none;
    color: #3f3f3e;

  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-map {
    margin-top: 40px;
    margin: 40px auto;
    width: 70%;
  }
  
  .footer-map iframe {
    width: 100%;
    height: 300px;
    border: 0;
    
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-bottom a {
    color: #3f3f3e;

    text-decoration: none;
  }
  
  .footer-bottom a:hover {
    text-decoration: underline;
  }
  
  .footer p {
    font-size: 14px;
  }
  
  .footer-link{
    color: #3f3f3e;

  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column; /* Stack sections vertically */
      align-items: center; /* Center align items */
      width: 100%; /* Full width */
    }

    .footer-section {
      margin-right: 0; /* Remove right margin */
      margin-bottom: 20px; /* Add bottom margin for spacing */
      text-align: center; /* Center text */
    }

    .footer-logo {
      width: 120px; /* Adjust logo size for mobile */
    }

    .footer-map {
      width: 100%; /* Full width for map */
    }

    .footer-bottom {
      font-size: 12px; /* Smaller font size for mobile */
    }
  }