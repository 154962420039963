.swiper {
  width: 100%;
  height: 95vh;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.swiper-slide:nth-child(1n) {
  background-image: url("../../assest/interiorDesignImage1.jpg");
}

.swiper-slide:nth-child(2n) {
  background-image: url("../../assest/interiorDesignImage2.jpg");
}

.swiper-slide:nth-child(3n) {
  background-image: url("../../assest/interiorDesignImage3.png");
}

.swiper-slide:nth-child(4n) {
  background-image: url("../../assest/interiorDesignImage4.jpeg");
}
.swiper-button-prev {
  width: 50px;
  height: 50px;
  background-color: #663c2a;
  position: absolute;
  top: 90%;
  right: 10px; /* Adjust position */
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
}

.swiper-button-prev::before {
  content: "←"; /* Unicode left arrow */
  color: white;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Right arrow */
.swiper-button-next {
  width: 50px;
  height: 50px;
  background-color: #663c2a;
  position: absolute;
  top: 80%;
  right: 10px; /* Adjust position */
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
}

.swiper-button-next::before {
  content: "→"; /* Unicode right arrow */
  color: white;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-slide .overlay-swiper {
  width: 100%;
  height: 100%;
  background: #00000034;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.swiper-slide .content h1 {
  position: absolute;
  z-index: 99;
  left: 10%;
  top: 30%;
  /* color: #663c2a; */
  color: #C1B59F;
  background: transparent !important;
  font-size: 4rem;
  backdrop-filter: invert(1);
  /* backdrop-filter: brightness(0.1); */
}
.swiper-slide .content h2 {
  position: absolute;
  z-index: 99;
  left: 10%;
  font-size: 3rem;
  color: #c4dbee;
  background: transparent !important;
  font-weight: 400;
  top: 40%;
  /* backdrop-filter:invert(1); */
  backdrop-filter: brightness(0.1);
}
/* Slide fade-in animation */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.content h1,
.content h2 {
  opacity: 0; /* Initially hidden */
  transform: translateY(150px); /* Move down initially */
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.active-slide h1,
.active-slide h2 {
  opacity: 1;
  transform: translateY(0); /* Slide up effect */
}

@media (max-width: 768px) {
  .swiper {
    width: 100%;
    height: 85vh;
  }
  .swiper-slide .content h1 {
    font-size: 2rem;
  }
  .swiper-slide .content h2 {
    font-size: 1.5rem;
    top: 38%;
  }
}
