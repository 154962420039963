.testimonial-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.testimonial {
  background-color: #fff;
  border: 10px solid #eaeaea;
  border-top: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  font-family: "Arial", sans-serif;
  position: relative;
}

.testimonial-text {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
  margin-bottom: 20px;
  max-height: 240px;
  overflow: scroll;
  text-overflow: ellipsis;
}
.testimonial::before {
  content: "";
  width: 70%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: #eaeaea;
}
.testimonial-text::after {
  content: "";
  width: 7%;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  background: #eaeaea;
}
.testimonial-info {
  font-weight: bold;
  font-size: 14px;
  position: relative;
}

.testimonial-info .name {
  display: block;
  color: #000;
}

.testimonial-info .location {
  display: block;
  font-size: 12px;
  color: #888;
}
.testimonial-info::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 25px solid #eaeaea;
  border-right: 30px solid transparent;
  position: absolute;
  left: 8px;
  top: 3.4rem;
}

.testimonial-section .swiper-slide:nth-child(1n) {
  background: transparent;
}
.testimonial-section {
  width: 100%;
  height: 400px;
  position: relative;
  max-width: 90%;
  margin: 0 auto;
  z-index: 0;
}

.testimonial-section .swiper {
  width: 100%;
  height: 100%;
}

.testimonial-section .swiper-button-prev {
  width: 50px;
  height: 50px;
  background-color: #C1B59F;
  position: absolute;
  top: 50%;
  left: 0; /* Adjust position */
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
}

.testimonial-section .swiper-button-prev::before {
  content: "←"; /* Unicode left arrow */
  color: white;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Right arrow */
.testimonial-section .swiper-button-next {
  width: 50px;
  height: 50px;
  background-color: #C1B59F;
  position: absolute;
  top: 50%;
  right: 0; /* Adjust position */
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
}

.testimonial-section .swiper-button-next::before {
  content: "→"; /* Unicode right arrow */
  color: white;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.testimonial-section .swiper-pagination-bullet {
  background: black;
}
.testimonial-section .swiper-pagination-bullet-active {
  background: #663c2a;
}
/* 


  
  .testimonial-section .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    border: 2px solid #663c2a;
    filter: grayscale(1);
    
  }
  .testimonial-section .swiper-slide:hover {
      filter: grayscale(0);
  }
  
  .testimonial-section .overlay-text {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: #00000078;
    width: 100%;
    color: white;
    height: 100px;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .testimonial-section .swiper-slide:hover  .overlay-text {
    opacity: 1;
  }
  .testimonial-section .overlay-text span {
    transform: rotate(-45deg);
  }
  .testimonial-section .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .testimonial-section .swiper {
    overflow: unset!important;
  }

   */
